const routes = {
  index: '/accounts',
  details: '/accounts/details/:id',
  eligibilityCategories: '/accounts/details/:id/eligibility-categories',
  merchantGuidelines: '/accounts/details/:id/merchant-gidelines',
  benefitRules: '/accounts/details/:id/benefit-rules',
  bankDetails: '/accounts/details/:id/bankDetails',
  reimbursements: '/accounts/reimbursements/:id',
  reimbursementTransferModal: '/accounts/reimbursements/:id/transfer',
  reimbursementTransactionsList: '/accounts/reimbursements/:id/transactions',
  payoutDetails: '/accounts/reimbursements/:id/details/:transaction',
  expenseDetails: '/accounts/expenses/details/:id',
  upcomingEvents: '/accounts/:id/upcoming-events',
};

export default routes;
