import { useState, useCallback } from 'react';
import DayPicker, { BeforeAfterModifier, DateUtils, DayModifiers } from 'react-day-picker';
import DateRangePickerOverlay from './DateRangePickerOverlay';
import DateRangePickerInput from './DateRangePickerInput';
import DateRangePickerContainer from './DateRangePickerContainer';
import DateRangePickerNavbar from './DateRangePickerNavbar';
import DateRangePickerShortcuts from './DateRangePickerShortcuts';
import Button from '../button';
import Flex, { JustifyContent } from '../../Flex';
import * as Popover from '@radix-ui/react-popover';

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export type DateRange = {
  from?: Date;
  to?: Date;
};
export type DisabledDays = BeforeAfterModifier;

export type Props = {
  onChange: (from?: Date, to?: Date) => void;
  initialDate?: {
    from: Date;
    to: Date;
  };
  disabledDays?: DisabledDays;
  excludedShortcuts?: string[];
  emptyTitle?: string;
  triggerClassName?: string;
  triggerTextClassName?: string;
};

const DateRangePicker = ({
  onChange,
  initialDate,
  disabledDays,
  excludedShortcuts,
  emptyTitle,
  triggerClassName,
  ...props
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [range, setRange] = useState({
    from: initialDate ? initialDate.from : undefined,
    to: initialDate ? initialDate.to : undefined,
  });
  const [appliedRange, setAppliedRange] = useState({
    from: initialDate ? initialDate.from : undefined,
    to: initialDate ? initialDate.to : undefined,
  });
  const [month, setMonth] = useState<Date | undefined>(initialDate?.from ?? new Date());
  const handleDayClick = useCallback((day: Date, modifiers?: DayModifiers) => {
    if (modifiers?.disabled) {
      return;
    }

    setRange((currentRange) => {
      const newRange = DateUtils.addDayToRange(day, currentRange);
      return {
        from: newRange.from ?? undefined,
        to: newRange.to ?? undefined,
      };
    });
  }, []);
  const handleSelectShortcut = useCallback((from?: Date, to?: Date) => {
    setRange({
      from,
      to,
    });
    setMonth(from);
  }, []);
  const handleYearMonthChange = useCallback((date: Date) => {
    setMonth(date);
  }, []);
  const handleApply = useCallback(() => {
    if (!isOpen) {
      return;
    }

    setAppliedRange(range);
    onChange(range.from, range.to);
    setOpen(false);
  }, [range, onChange, isOpen]);
  const { from, to } = range;
  const modifiers = {
    start: from,
    end: to,
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={setOpen}>
      <Popover.Trigger asChild className={triggerClassName}>
        <DateRangePickerInput
          from={appliedRange.from}
          to={appliedRange.to}
          emptyTitle={emptyTitle}
          textClassName={props.triggerTextClassName || undefined}
        />
      </Popover.Trigger>

      <Popover.Content align="end" sideOffset={8}>
        <DateRangePickerContainer>
          <DateRangePickerOverlay
            shortcuts={
              excludedShortcuts?.includes('all') ? undefined : (
                <DateRangePickerShortcuts
                  onSelect={handleSelectShortcut}
                  maxDate={disabledDays?.after}
                  excludedShortcuts={excludedShortcuts}
                />
              )
            }
          >
            <DayPicker
              weekdaysShort={WEEKDAYS_SHORT}
              modifiers={modifiers}
              disabledDays={disabledDays}
              selectedDays={[
                from,
                {
                  from,
                  to,
                },
              ]}
              onDayClick={handleDayClick}
              month={month}
              navbarElement={() => null} // eslint-disable-next-line react/display-name
              // eslint-disable-next-line react/no-unused-prop-types
              captionElement={({ date }: { date: Date }): JSX.Element => (
                <DateRangePickerNavbar date={date} onChange={handleYearMonthChange} />
              )}
            />
            <Flex justifyContent={JustifyContent.FLEX_END}>
              <Button className="mt-2" size="sm" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          </DateRangePickerOverlay>
        </DateRangePickerContainer>
      </Popover.Content>
    </Popover.Root>
  );
};

export default DateRangePicker;
