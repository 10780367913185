import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import Route from '@app/components/route';
import routes from '.';

const AccountsPage = lazy(() => import('./accounts-page'));
const BenefitDetailsPage = lazy(() => import('./details/benefit'));
const EligibilityCategories = lazy(
  () => import('./details/benefit/components/modals/eligibility-categories-list-modal')
);
const BankDetails = lazy(() => import('./details/benefit/components/modals/bank-details-modal'));
const UpcomingEvents = lazy(() => import('./details/upcoming-events'));
const BenefitRules = lazy(() => import('./details/benefit/components/modals/benefit-rules-modal'));
const MerchantGuidelines = lazy(() => import('./details/benefit/components/modals/merchant-guidelines-modal'));
const ReimbursementsPage = lazy(() => import('./details/reimbursement'));
const AllReimbursementsPage = lazy(() => import('./details/reimbursement/pages/all-reimbursements'));
const PayoutDetailsPage = lazy(() => import('./details/reimbursement/pages/payout-details'));
const TransferModal = lazy(() => import('./details/reimbursement/pages/transfer-modal'));
const TransactionDetailsPage = lazy(() => import('@pages/transactions/details'));

const Accounts = () => (
  <>
    <Switch>
      <Route path={[routes.payoutDetails]} title="Transaction details">
        <PayoutDetailsPage />
      </Route>
      <Route path={[routes.expenseDetails]} title="Transaction details">
        <TransactionDetailsPage />
      </Route>
      <Route path={[routes.reimbursementTransactionsList]} title="Transaction details">
        <AllReimbursementsPage />
      </Route>
      <Route path={[routes.details]} title="Account details">
        <BenefitDetailsPage />
      </Route>
      <Route path={[routes.reimbursements]} title="Reimbursements">
        <ReimbursementsPage />
      </Route>
      <Route path={[routes.upcomingEvents]} title="Upcoming events">
        <UpcomingEvents />
      </Route>
      <Route path={[routes.index]} title="Accounts">
        <AccountsPage />
      </Route>
    </Switch>
    <Switch>
      <Route path={[routes.reimbursementTransferModal]} title="Transfer funds">
        <TransferModal />
      </Route>
      <Route exact path={[routes.eligibilityCategories]} title="Eligible categories">
        <EligibilityCategories />
      </Route>
      <Route exact path={[routes.merchantGuidelines]} title="Merchant guidelines">
        <MerchantGuidelines />
      </Route>
      <Route exact path={[routes.benefitRules]} title="Benefit rules">
        <BenefitRules />
      </Route>
      <Route exact path={[routes.bankDetails]} title="Benefit rules">
        <BankDetails />
      </Route>
    </Switch>
  </>
);

export default Accounts;
